import { Component } from '@angular/core'

@Component({
  template: `<div [innerHTML]="api.formattedAddress"></div>`,
})
export class ConfirmAddressComponent {
  api!: {
    formattedAddress: string
  }
}
